import Vue from "vue";
import moment from "moment";
import timezone from "moment-timezone";
import store from "@/store";

Vue.filter("MMDDYYdatefilter", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("MM/DD/YY");
});

Vue.filter("Currencyfilter", function (value, countryCode) {
  var currencyCodeData = {
    us: 'USD',
    mx: 'MXN',
    eg: 'EGP',
    es: 'EUR',
    gh: 'GHS'
  };
  if (!value) return "";
  value = value.toString();
  let formattedValue = new Intl.NumberFormat('en-'+countryCode.toUpperCase(), {
    style: 'currency',
    currency: currencyCodeData?.[countryCode],
  });
  return formattedValue.format(value);
});

Vue.filter("CurrencySymbol", function (value, countryCode) {
  var currencyCodeData = {
    us: '$',
    mx: 'MX$',
    eg: '£',
    es: '€',
    gh: 'GH₵'
  };

  return currencyCodeData?.[countryCode];
});


Vue.filter("USCurrencyfilter", function (value) {
  if (!value) return "$0.00";
  if(value >= 0) {
    var sign = '$';
  } else {
    var sign = '-$';
    value = Math.abs(value);
  }
  value = value.toString();
  return (
    sign +
    Number(value)
      .toFixed(2)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
  );
});

Vue.filter("DateTimezoneFilter", function (value) {
  const user = store.getters["user/getUser"];
  let userTimeZone = "UTC";
  if (user && user.user_detail && user.user_detail.timezone) {
    userTimeZone = user.user_detail.timezone;
  }
  if (!value) return "";
  value = value.toString();
  const utc = timezone.utc(value).toDate();
  const localTime = timezone(utc).tz(userTimeZone).format("MM/DD/YY h:mmA");
  return localTime;
});

Vue.filter("USFormatNumber", function (value) {
  if (!value) return "";
  const formattedValue = value
    .toString()
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !formattedValue[2]
    ? formattedValue[1]
    : "(" +
        formattedValue[1] +
        ") " +
        formattedValue[2] +
        (formattedValue[3] ? "-" + formattedValue[3] : "");
});

Vue.filter("UCFirstChars", function (value) {
  if(value) {
    value = value.replace(/[^a-zA-Z0-9 ]/g, " ");
    value = value.replace(/\s\s+/g, ' ');
    return value.toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });
  } else {
    return "";
  }
});

Vue.directive('uppercase', {
	inserted: function(el, _, vnode) {
    el.addEventListener('input', async function(e) {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  }
})