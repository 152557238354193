import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { loadLanguageAsync } from "@/plugins/i18n";
import authUtility from "@/utils/auth.utility";
import {
  CAR_AUCTION,
  CAR_HISTORY_REPORT,
  DMV_PROCESSING,
  INTERCHANGE,
  INVOICE,
  MEMBERS_DIRECTORY,
  POSTING,
  SEARCH_INVENTORY,
  SELLING_EBAY,
  TRADE_CAR_PARTS,
  VIN_SEARCH,
  BOOKLOG,
  MARKETING_CAMPAIGN,
  HOTLINE_NEWS
} from "@/constants/services";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { addHtmlClass: true }
  },
  {
    path: "/terms-condition",
    name: "TermCondition",
    component: () => import("../views/TermCondition.vue"),
    meta: { addHtmlClass: true }
  },
  {
    path: "/leads",
    name: "Leads",
    component: () => import("../views/Leads.vue"),
    meta: { addHtmlClass: true }
  },
  {
    path: "/payment-information",
    name: "PaymentInformation",
    component: () => import("../views/PaymentInformation.vue"),
  },

  {
    path: "/payment-details",
    name: "PaymentDetails",
    component: () => import("../views/PaymentDetails.vue"),
  },

  {
    path: "/duplicate-tab",
    name: "DuplicateTab",
    component: () => import("../views/DuplicateTab.vue"),
    meta: { addHtmlClass: true }
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    children: [
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "",
        name: "MembersDirectory",
        component: () => import("../views/MembersDirectory.vue"),
        meta: {
          service: MEMBERS_DIRECTORY,
        },
      },
      {
        path: "car-history",
        name: "CarHistory",
        component: () => import("../views/CarHistory.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "car-history-result",
        name: "CarHistoryResult",
        component: () => import("../views/CarHistoryResult.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "vehicle-history",
        name: "VehicleHistory",
        component: () => import("../views/VehicleHistory.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "vehicle-history-result",
        name: "VehicleHistoryResult",
        component: () => import("../views/VehicleHistoryResult.vue"),
        meta: {
          service: CAR_HISTORY_REPORT,
        },
      },
      {
        path: "vin-search",
        name: "VinSearch",
        component: () => import("../views/VinSearch.vue"),
        meta: {
          service: VIN_SEARCH,
        },
      },
      {
        path: "vin-search-result",
        name: "VinSearchResult",
        component: () => import("../views/VinSearchResult.vue"),
        meta: {
          service: VIN_SEARCH,
        },
      },
      {
        path: "message-center",
        name: "MessageCenter",
        component: () => import("../views/MessageCenter.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "post-thread/:postThreadId/messages",
        name: "ThreadMessages",
        component: () => import("../views/Messages.vue"),
        meta: {
          service: POSTING,
        },
      },
      {
        path: "interchange",
        name: "InterChange",
        component: () => import("../views/InterChange.vue"),
        meta: {
          service: INTERCHANGE,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "booklog",
        name: "BookLog",
        component: () => import("../views/BookLog.vue"),
        meta: {
          service: BOOKLOG,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "vehicleInfo",
        name: "AddVehicle",
        component: () => import("../components/bookLog/addUpdateVehicle.vue"),
        meta: {
          service: BOOKLOG,
        },
      },
      {
        path: "vehicleInfo/:vehicleId",
        name: "UpdateVehicle",
        component: () => import("../components/bookLog/addUpdateVehicle.vue"),
        meta: {
          service: BOOKLOG,
        },
      },
      {
        path: "marketing-campaign",
        name: "MarketingCampaign",
        component: () => import("../views/MarketingCampaign.vue"),
        meta: {
          service: MARKETING_CAMPAIGN,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "marketing-phone",
        name: "MarketingPhone",
        component: () => import("../components/marketingCampaign/marketingPhone.vue"),
        meta: {
          service: MARKETING_CAMPAIGN,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "marketing-email",
        name: "MarketingEmail",
        component: () => import("../components/marketingCampaign/marketingEmail.vue"),
        meta: {
          service: MARKETING_CAMPAIGN,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "campaign-info",
        name: "AddCampaign",
        props: true,
        component: () =>
          import("../components/marketingCampaign/addCampaign.vue"),
        meta: {
          service: MARKETING_CAMPAIGN,
        },
      },
      {
        path: "campaign-history",
        name: "HistoryCampaign",
        component: () =>
          import("../components/marketingCampaign/historyCampaign.vue"),
        meta: {
          service: MARKETING_CAMPAIGN,
        },
      },
      {
        path: "interchange-result",
        name: "InterChangeResults",
        component: () => import("../views/InterChangeResult.vue"),
        meta: {
          service: INTERCHANGE,
        },
      },
      {
        path: "serach-inventory",
        name: "SearchInventory",
        component: () => import("../views/SearchInventory.vue"),
        meta: {
          service: SEARCH_INVENTORY,
        },
      },
      {
        path: "billing-invoice",
        name: "BillingInvoice",
        component: () => import("../views/BillingInvoice.vue"),
        meta: {
          service: INVOICE,
        },
      },
      {
        path: "billing-invoice/dashboard",
        name: "BillingInvoiceDashboard",
        component: () => import("../components/invoice/invoiceDashboard.vue"),
        meta: {
          service: INVOICE,
        },
      },
      {
        path: "billing-invoice/create",
        name: "BillingInvoiceCreate",
        component: () => import("../components/invoice/createInvoice.vue"),
        meta: {
          service: INVOICE,
        },
      },
      {
        path: "billing-invoice/view",
        name: "BillingInvoiceView",
        props: true,
        component: () => import("../components/invoice/viewInvoice.vue"),
        meta: {
          service: INVOICE,
        },
      },
      // {
      //   path: "billing-invoice/invoice/:invoiceId",
      //   name: "InvoiceView",
      //   component: () => import("../components/invoice/invoice.vue"),
      //   meta: {
      //     service: INVOICE,
      //   },
      // },
      {
        path: "billing-invoice/report",
        name: "BillingInvoiceReport",
        props: true,
        component: () => import("../components/invoice/reportInvoice.vue"),
        meta: {
          service: INVOICE,
        },
      },
      {
        path: "dmv-processing/:processingType?",
        name: "DMVProcessing",
        component: () => import("../views/DMVProcessing.vue"),
        meta: {
          service: DMV_PROCESSING,
        },
      },
      {
        path: "car-auctions",
        name: "CarAuctions",
        component: () => import("../views/CarAuctions.vue"),
        meta: {
          service: CAR_AUCTION,
        },
      },
      {
        path: "trade-car-parts",
        name: "TradeCarParts",
        component: () => import("../views/TradeCarParts.vue"),
        meta: {
          service: TRADE_CAR_PARTS,
        },
      },
      {
        path: "selling-ebay",
        name: "SellingEbay",
        component: () => import("../views/SellingEbay.vue"),
        meta: {
          service: SELLING_EBAY,
        },
      },
      {
        path: "hotline-news",
        name: "HotlineNews",
        component: () => import("../views/HotlineNews.vue"),
        meta: {
          service: HOTLINE_NEWS,
        },
      },
      {
        path: "hotline-news/view",
        name: "ViewHotlineNews",
        props: true,
        component: () => import("../components/hotlineNews/ViewHotlineNews.vue"),
        meta: {
          service: HOTLINE_NEWS,
          notInCountry: ['mx', 'eg', 'es', 'gh']
        },
      },
      {
        path: "previous-invoices",
        name: "PreviousInvoices",
        component: () => import("../views/PreviousInvoice.vue"),
      },
      {
        path: "previous-car-history",
        name: "PreviousCarHistory",
        component: () => import("../views/PreviousCarHistory.vue"),
      },
      {
        path: "previous-vehicle-history",
        name: "PreviousVehicleHistory",
        component: () => import("../views/PreviousVehicleHistory.vue"),
      },
      {
        path: "previous-vin-search",
        name: "PreviousVinSearch",
        component: () => import("../views/PreviousVinSearch.vue"),
      },
      {
        path: "payment-info",
        name: "PaymentInfo",
        component: () => import("../components/profile/PaymentInfo.vue"),
      },
      {
        path: "/core-buying-service",
        name: "CoreBuying",
        component: () => import("../views/CoreBuying.vue"),
      },
      //for member user login
      {
        path: "/core-buying",
        name: "CoreBuyingMember",
        component: () => import("../views/CoreBuyingMember.vue"),
        meta: {
          notInCountry: ['mx', 'eg', 'es', 'gh']
        }
      },
      {
        path: "unauthorized",
        name: "UnAuthorized",
        component: () => import("../views/UnAuthorized.vue"),
      },
      {
        path: "coming-soon",
        name: "ComingSoon",
        component: () => import("../views/ComingSoon.vue"),
      },
      {
        path: "forbidden",
        name: "Forbidden",
        component: () => import("../views/Forbidden.vue"),
      },
      {
        path: "404-error",
        name: "404Error",
        component: () => import("../views/404Error.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  //if (to.meta.addHtmlClass) {
  //  document.documentElement.classList.add('has-scroll');
  //} else {
  //  document.documentElement.classList.remove('has-scroll');
  //}
  //next(); 

  console.log("1. to: ", to.name);
  console.log("2. from: ", from.name);
  const lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : process.env.VUE_APP_LANGUAGE;
  await loadLanguageAsync(lang);
  const user = store.getters["user/getUser"];
  const publicPages = ["Login", "Leads", "TermCondition", "DuplicateTab"];

  document.documentElement.classList.remove('has-scroll');

  if (to.name === "Login" && store.getters["user/isLoggedIn"]) {
    next({ path: "/dashboard" });
  } else if ( store.getters["user/isLoggedIn"] && !publicPages.includes(to.name) && to.name !== "PaymentDetails") {
    if(to.meta.notInCountry && to.meta.notInCountry.length > 0 && to.meta.notInCountry.includes(user.user_detail.country)) {
      next({ path: "/dashboard" });
    } else if (user && user.is_new_user && !user.is_subscribed) {
      next({ name: "PaymentDetails" });
    } else if (user && !user.is_subscribed && !user.is_on_grace_period) {
      next({ name: "PaymentDetails" });
    } else if (user && user.has_failed_payment && !user.is_on_grace_period) {
      next({ name: "PaymentDetails" });
    } else if ( to.meta.service && !authUtility.hasAccess( to.meta.service, store.getters["user/getUserServices"] ) && authUtility.isServiceActive( to.meta.service, store.getters["user/getAllServices"])) {
      next({ name: "UnAuthorized" });
    } else if ( to.meta.service && !authUtility.isServiceActive(  to.meta.service, store.getters["user/getAllServices"])) {
      next({ name: "ComingSoon" });
    } else {
      next();
    }
  } else if ( !publicPages.includes(to.name) && !store.getters["user/isLoggedIn"]) {
    next({ name: "Login" });
  } else if ( to.meta.service && !authUtility.hasAccess( to.meta.service, store.getters["user/getUserServices"])) {
    next({ name: "UnAuthorized" });
  } else if (user && user.is_subscribed) {
    next({ name: "MembersDirectory" });
  } else {
    next();
  }
});

export { routes };
export default router;
